import React, { useState, useEffect } from "react"
import cx from "classnames"
import LazyLoad from "react-lazyload"
import htmlParser from "react-html-parser"
import is from "react-jss"

import Dot from "./components/Dot"
import Button from "../../../Button"
import BackgroundText from "../../../BackgroundText"
import Link from "../../../Link"

import { withIsVisible } from "../../../../helpers"
import tpLogo from "../../../../../assets/images/tp-logo.png"
import tpgLogo from "../../../../../assets/images/logo-tpg.png"
import budgeLogo from "../../../../../assets/images/logo-budge.png"
import logoAng from "../../../../../assets/images/logo-ang.png"

import style from "./style"

const ScaleMomentum = ({
  classes,
  isVisible = false,
  content: {
    scaleMomentum: { powerUp, powerUpCopy, teamUp, teamUpCopy },
  },
}) => {
  const [activeState, setActiveState] = useState({
    active: true,
    item: 0,
    powerUp: true,
  })

  const next = () => {
    if (powerUp && teamUp) {
      if (
        (activeState.powerUp && activeState.item >= powerUp.length - 1) ||
        (!activeState.powerUp && activeState.item >= teamUp.length - 1)
      ) {
        setActiveState({ active: true, item: 0, powerUp: !activeState.powerUp })
      } else {
        setActiveState({
          active: true,
          item: activeState.item + 1,
          powerUp: activeState.powerUp,
        })
      }
    }
  }

  const handleAutomatedHover = (item, powerUp, active) => {
    setActiveState({ active, item, powerUp })

    if (active) {
      if (
        (activeState.powerUp && activeState.item >= powerUp.length - 1) ||
        (!activeState.powerUp && activeState.item >= teamUp.length - 1)
      ) {
        setActiveState({ active, item: 0, powerUp: !activeState.powerUp })
      } else {
        setActiveState({
          active,
          item: activeState.item + 1,
          powerUp: activeState.powerUp,
        })
      }
    } else {
      setActiveState({ active, item, powerUp })
    }
  }

  useEffect(() => {
    let interval = null

    if (isVisible && activeState.active) {
      interval = setInterval(() => {
        next()
      }, 3500)
    } else {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [isVisible, activeState])

  return (
    <>
      <div className={classes.panelDN}>
        <div className={classes.backgroundDnR}></div>
        <div className={classes.backgroundDnL}></div>
        <div className={classes.containerDn}>
          <div className={classes.innerDn}>
            <div className={classes.devNetwork}>
              <h2 className={classes.titleDn}>SPECIALIZED STUDIOS</h2>
              <figure className={classes.devNetworkLogo}>
                <img src={tpLogo} alt="" />
              </figure>
              <div className={classes.devNetworkChart}>
                <article className={classes.devNetworkChartOne}>
                  <figure className={classes.devNetworkChartLogo}>
                    <img src={tpgLogo} alt="" />
                  </figure>
                  <div className={classes.devNetworkChartInfo}>
                    Primary & Legacy Portfolio
                  </div>
                </article>
                <article className={classes.devNetworkChartOne}>
                  <figure className={classes.devNetworkChartLogo}>
                    <img src={budgeLogo} alt="" />
                  </figure>
                  <div className={classes.devNetworkChartInfo}>
                    Young Audience Games Specialists
                  </div>
                </article>
                <article className={classes.devNetworkChartOne}>
                  <figure className={classes.devNetworkChartLogo}>
                    <img src={logoAng} alt="" />
                  </figure>
                  <div className={classes.devNetworkChartInfo}>
                    MMO Strategy & 4x Games Specialists
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default is(style)(withIsVisible(ScaleMomentum))
