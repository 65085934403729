import React, { useState } from "react"
import * as _ from "underscore"
import cx from "classnames"
import is from "react-jss"

import style from "./style"
import Button from "../../../../../Button"
import Link from "../../../../../Link"

const Buttons = ({ classes }) => {
  const [loaded] = useState(true)

  return (
    <div className={cx(classes.social, { loaded })}>
      <Link to={"/contact/#form"}>
        <Button>work with us</Button>
      </Link>
      <Link to={"https://tiltingpointfund.com"}>
        <Button className={cx(classes.wwd)}>UA FUNDING</Button>
      </Link>
    </div>
  )
}

export default is(style)(Buttons)
